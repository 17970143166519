define([
    'react',
    'create-react-class',
    'lodash',
    'warmupUtilsLib'
], function (
    React,
    createReactClass,
    _,
    warmupUtilsLib
) {
    'use strict';

    const compsDefinitions = {};
    const compsExtraMixins = {};
    const pendingExtend = {};

    let mobxDefinition; // eslint-disable-line santa/no-module-state
    let wixHOC; // eslint-disable-line santa/no-module-state

    // eslint-disable-next-line santa/no-module-state
    const moduleState = {
        compFactories: {},
        originalCompFactories: {},
        compClasses: {},
        compExtensions: {}
    };

    const isCompDefinition = definitionOrClass => _.isPlainObject(definitionOrClass);

    function getReactClass(definitionOrClass, name, runtimeState) {
        const state = _.defaultTo(runtimeState, moduleState);

        let CompClass = definitionOrClass;
        if (isCompDefinition(definitionOrClass)) {
            CompClass = createReactClass({
                displayName: definitionOrClass.displayName || name,

                //eslint-disable-line react/display-name
                mixins: [definitionOrClass].concat(compsExtraMixins[name])
            });
        }

        const allExtensions = _.compact([].concat(state.compExtensions[name]).concat(state.compExtensions['*']));
        return _.flow(allExtensions)(CompClass, name);
    }

    function lazyCreateCompFactories(name, forceOriginalCompClass, runtimeState) { // eslint-disable-line complexity
        const state = _.defaultTo(runtimeState, moduleState);
        const {compFactories, compClasses, originalCompFactories} = state;

        let componentFactory = forceOriginalCompClass ? originalCompFactories[name] : compFactories[name];
        if (!componentFactory) {
            const compDef = _.get(compsDefinitions, [name]);
            if (!compDef) {
                warmupUtilsLib.log.error('Component not implemented:', name);
                return;
            }

            const componentClass = getReactClass(compDef, name, state);

            compClasses[name] = componentClass;
            componentFactory = React.createElement.bind(null, componentClass);
            componentFactory.type = componentClass;
            originalCompFactories[name] = componentFactory;

            if (wixHOC && mobxDefinition) {
                const wrappedComponentClass = wixHOC(name, componentClass, mobxDefinition);
                compFactories[name] = React.createElement.bind(null, wrappedComponentClass);
                compFactories[name].type = wrappedComponentClass;
            } else if (!wixHOC && !mobxDefinition) { // this is for bolt support, where wixHOC && mobxDefinition do not exist
                compFactories[name] = componentFactory;
            }
        }
    }

    /**
     * @class core.compFactory
     */
    const compFactory = {

        /**
         * Gets the cached react factory of the component or instantiating it on-demand
         * @param name The component name
         * @returns {Function|undefined} The react factory for the requested component name
         */
        getCompClass(name, forceOriginalCompClass, runtimeState) {
            const state = _.defaultTo(runtimeState, moduleState);

            lazyCreateCompFactories(name, false, state);

            if (forceOriginalCompClass) {
                return state.originalCompFactories[name];
            }

            return state.compFactories[name];
        },

        getCompReactClass(name, forceOriginalCompClass, runtimeState) {
            const state = _.defaultTo(runtimeState, moduleState);

            lazyCreateCompFactories(name, forceOriginalCompClass, state);

            return state.compClasses[name];
        },

        /**
         * Invalidates the component class. This means that you can register a different
         * class and have a new class next time a component is rendered. This is for runtime change of the
         * class, and should be used mainly for debugging purposes
         * @param name The name of the component
         */
        invalidate(name, runtimeState) {
            const state = _.defaultTo(runtimeState, moduleState);
            delete state.compFactories[name];
        },

        /**
         * @deprecated
         * Allows extending the definition of a component class by extension packages. This
         * is used for enrichment of viewer component for preview, automation qa, editor decorations, etc.
         * @param name The name of the component to extend
         * @param compDefinitionExtension The overriding methods and properties of the component
         */
        extend(name, compDefinitionExtension) {
            if (!compsDefinitions.hasOwnProperty(name)) {
                pendingExtend[name] = compDefinitionExtension;
                return;
            }

            if (compsDefinitions[name].componentOverride) {
                return;
            }

            if (!isCompDefinition(compsDefinitions[name])) {
                throw new Error(`Unable to extend registered react class ${name}`);
            }

            compFactory.invalidate(name);
            if (!compsExtraMixins[name] || compsExtraMixins[name] !== undefined) {
                compsExtraMixins[name] = _.union(compsExtraMixins[name].concat(compDefinitionExtension));
            }
        },

        /**
         * Registers a component definition in the component factory
         * @param {string} name The name of the component
         * @param {comp.reactComponent} compDefinition The js object (dictionary) that defines the component. It will instantiated
         * when used for the first time. In order to change in runtime, use the invalidate method.
         */
        register(name, compDefinition) {
            if (!compsDefinitions[name] || (compsDefinitions[name] !== undefined && !compsDefinitions[name].componentOverride)) {
                compsDefinitions[name] = compDefinition;
                compsExtraMixins[name] = [];
            }
            if (pendingExtend[name]) {
                compFactory.extend(name, pendingExtend[name]);
                delete pendingExtend[name];
            }
            return compFactory;
        },

        isRegistered(name) {
            return !!compsDefinitions[name];
        },

        registerExtension(name, extension, runtimeState) {
            const state = _.defaultTo(runtimeState, moduleState);
            if (!state.compExtensions[name] || state.compExtensions[name] !== undefined) {
                state.compExtensions[name] = state.compExtensions[name] || [];
                state.compExtensions[name].push(extension);
            }
        },

        registerCommonExtensionForAllComponents(extension, runtimeState) {
            compFactory.registerExtension('*', extension, runtimeState);
        },

        unregisterExtension(name, extension, runtimeState) {
            const state = _.defaultTo(runtimeState, moduleState);
            _.pull(state.compExtensions[name], extension);
        },

        unregisterCommonExtensionFromAllComponents(extension, runtimeState) {
            compFactory.unregisterExtension('*', extension, runtimeState);
        },

        registerMobxObserver(compDefinition) {
            mobxDefinition = compDefinition;
            return compFactory;
        },

        registerWixHOC(wixHOCInstance) {
            wixHOC = wixHOCInstance;
        },

        keys() {
            return _.keys(compsDefinitions);
        },

        generateRuntimeState() {
            return {
                compClasses: {},
                originalCompFactories: {},
                compFactories: {},
                compExtensions: {}
            };
        }
    };

    return compFactory;
});
