define([
    'santa-components',
    'lodash',
    'react',
    'prop-types',
    'create-react-class'
], function (santaComponents, _, React, PropTypes, createReactClass) {
    'use strict';

    const createReactElement = santaComponents.utils.createReactElement;
    return createReactClass({
        displayName: 'fullScreenOverlay',
        propTypes: {
            siteWidth: PropTypes.number.isRequired,
            siteScrollingBlocker: PropTypes.object.isRequired,
            forceBackground: PropTypes.func.isRequired,
            disableForcedBackground: PropTypes.func.isRequired,
            isMobileDevice: PropTypes.bool.isRequired,
            overlayBackgroundOpacity: PropTypes.string,
            children: PropTypes.node
        },
        statics: {
            createOverlay(skinProperties, overlayProps) {
                const wrapperFactory = React.createElement.bind(null, this);
                wrapperFactory.type = this;
                skinProperties[''] = _.assign({}, skinProperties[''], {wrap: [wrapperFactory, overlayProps]}); // eslint-disable-line santa/no-side-effects
                return skinProperties;
            }
        },
        getOverlayBackground() {
            const opacity = this.props.overlayBackgroundOpacity || 1;
            return `rgba(0, 0, 0, ${opacity})`;
        },

        componentDidMount() {
            this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, true);
            if (this.props.isMobileDevice) {
                this.props.forceBackground(this.getOverlayBackground());
            }
        },

        componentWillUnmount() {
            this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, false);
            if (this.props.isMobileDevice) {
                this.props.disableForcedBackground();
            }
        },

        render() {
            return createReactElement('div', null,
                createReactElement('div',
                    {
                        className: 'fullScreenOverlay',
                        key: 'fullScreenOverlay'
                    },
                    createReactElement('div',
                        {
                            className: 'fullScreenOverlayContent',
                            style: {
                                width: this.props.siteWidth,
                                background: this.getOverlayBackground()
                            },
                            key: 'fullScreenOverlayContent'
                        },
                        this.props.children
                    )));
        }
    });
});
