define([
    'lodash',
    'react',
    'prop-types',
    'create-react-class',
    'reactDOM',
    'zepto',
    'componentsCore/components/transitionGroup/transitionGroup',
    'componentsCore/components/transitionGroup/wixTransitionItem',
    'santa-components'
], function (
    _,
    React,
    PropTypes,
    createReactClass,
    reactDOM,
    $,
    transitionGroup,
    wixTransitionItem,
    santaComponents
) {
    'use strict';

    const transitionGroupFactory = React.createElement.bind(null, transitionGroup);
    transitionGroupFactory.type = transitionGroup;
    // wixTransitionItem = React.createElement.bind(null, wixTransitionItem);
    // wixTransitionItem.type = wixTransitionItem;

    function markTransitionDataAttr(refs, leaving) {
        _(refs)
            .map(reactDOM.findDOMNode)
            .map($)
            .forEach(function ($node) {
                $node.attr('data-leaving', leaving ? true : null);
            });
    }

    const wixTransitionGroup = createReactClass({
        displayName: 'wixTransitionGroup',
        mixins: [santaComponents.mixins.animationsMixin],
        propTypes: {
            getTransitionParams: PropTypes.func,
            transition: PropTypes.string,
            transitionDuration: PropTypes.number,
            reverse: PropTypes.bool,
            transitionCallback: PropTypes.func
        },

        getDefaultProps() {
            return {
                getTransitionParams: () => ({}),
                transition: 'CrossFade',
                transitionDuration: 2,
                reverse: false,
                transitionCallback: _.noop
            };
        },
        getInitialState() {
            this.leavingChildren = {};
            this.enteringChildren = {};
            return {};
        },
        render() {
            return transitionGroupFactory(_.assign({
                childFactory: this.childWrapper,
                component: 'div',
                ref: 'group'
            }, _.omit(this.props, 'transition', 'transitionDuration', 'reverse', 'transitionCallback', 'animations', 'isSiteBusy')));
        },
        childWrapper(child) {
            const refInParent = child.props.refInParent || child.props.id;
            return santaComponents.utils.createReactElement(wixTransitionItem, {
                onWillEnter: this.onWillEnter,
                onWillLeave: this.onWillLeave,
                refInParent,
                ref: refInParent,
                key: refInParent
            }, child);
        },
        getInnerRefs(refs) {
            return _.map(refs, function (ref) {
                return this.refs.group.refs[ref];
            }.bind(this));
        },
        flush() {
            if (!_.isEmpty(this.enteringChildren) && _.size(this.leavingChildren) === _.size(this.enteringChildren)) {
                const leavingKeys = this.getInnerRefs(_.keys(this.leavingChildren));
                const enteringKeys = this.getInnerRefs(_.keys(this.enteringChildren));

                markTransitionDataAttr(leavingKeys, true);
                markTransitionDataAttr(enteringKeys, false);

                const callbacks = _.values(this.enteringChildren).concat(_.values(this.leavingChildren));
                this.leavingChildren = {};
                this.enteringChildren = {};

                const params = this.props.getTransitionParams();
                this.transition(leavingKeys, enteringKeys, this.props.transition, this.props.transitionDuration, 0,
                    _.assign({reverse: this.props.reverse}, params), {
                        onComplete: function () {
                            this.props.transitionCallback(function () {
                                _.forEach(callbacks, function (callback) {
                                    callback();
                                });
                            });
                        }.bind(this)
                    });
            }
        },
        onWillEnter(ref, callback) {
            this.enteringChildren[ref] = callback;
            this.flush();
        },
        onWillLeave(ref, callback) {
            this.leavingChildren[ref] = callback;
            this.flush();
        }
    });

    return wixTransitionGroup;
});
